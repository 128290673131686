<script lang="ts">
import { defineComponent } from "vue";
import getEnv from "@/utils/env";

export default defineComponent({
  name: "LogoutPage",
  mounted() {
    const COGNITO_REDIRECT_URI = getEnv("VUE_APP_SQUID_COGNITO_REDIRECT_URI");
    const COGNITO_CLIENT_ID = getEnv("VUE_APP_SQUID_COGNITO_CLIENT_ID");
    const COGNITO_APP_DOMAIN = getEnv("VUE_APP_SQUID_COGNITO_APP_DOMAIN");
    // const LOGOUT_URL = `${getEnv("VUE_APP_SQUID_BASE_URL")}`;
    const LOGOUT_URL = `${getEnv("VUE_APP_SQUID_BASE_URL")}/auth/login`;

    // window.location.href = `https://${COGNITO_APP_DOMAIN}/logout?client_id=${COGNITO_CLIENT_ID}&logout_uri=${LOGOUT_URL}&redirect_uri=${COGNITO_REDIRECT_URI}&response_type=code`;
    window.location.href = `https://${COGNITO_APP_DOMAIN}/logout?client_id=${COGNITO_CLIENT_ID}&logout_uri=${LOGOUT_URL}&redirect_uri=${COGNITO_REDIRECT_URI}&response_type=code`;
  },
});
</script>
