<template>
  <div class="input__group__list__search">
    <BaseIcon class="input__group__list__search__svg" icon="form/search" />
    <BaseInput
      v-model="searchString"
      placeholder="Search by serial number and cmms id"
      type="text"
      class="input__group__list__search__input"
    />
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, onMounted } from "vue";
import BaseInput from "@/components/form/BaseInput.vue";
import BaseIcon from "@/components/svg/BaseIcon.vue";
import store from "@/store";

export default defineComponent({
  name: "FilterSearchInput",
  components: { BaseIcon, BaseInput },

  setup() {
    onMounted(() => {
      store.commit("filters/SET_SEARCH_STRING", "");
    });

    const searchString = computed<string>({
      get: () => store.getters["filters/searchString"] || "",
      set: (value: string) => {
        store.commit("filters/SET_SEARCH_STRING", value);
      },
    });

    return {
      searchString,
    };
  },
});
</script>

<style scoped>
.input__group__list__search {
  @apply flex-center bg-white;
  height: 40px;
  border: 1px solid rgba(0, 0, 0, 0.12);
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  width: 272px;

  /*border-radius: 5px;*/
}

.input__group__list__search__svg {
  margin: 5px 7px;
  border-radius: 0;
}

input.input__group__list__search__input {
  @apply flex-1 bg-white;
  border: 0 solid #aaa;

  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.25px;
  padding: 0;
}

input.input__group__list__search__input:focus {
  border: none;
}
</style>
