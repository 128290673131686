import { ActionTree } from "vuex";
import { RootState } from "@/store/types";
import { FilterState } from "@/store/modules/filters/types";

export const actions: ActionTree<FilterState, RootState> = {
  resetFilter({ commit }) {
    const hospitals = this.getters["devices/hospitals"];

    const hospitalName =
      hospitals && hospitals.length > 0 && hospitals[0].name
        ? hospitals[0].name
        : "";

    const defaultFilter = {
      organization: hospitalName,
      department: "",
      category: "",
      subCategory: "",
      vendor: "",
      reconciliationStatuses: [],
      deviceStatuses: "",
      locationUnit: "",
    };
    commit("SET_FILTERS", defaultFilter);
  },
};
